














































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { CBtn } from "@/models/utils/c-btn.interface";
import { AuditRequest } from "@/models/requests/audit-request.interface";
import { AuditAlert } from "@/models/entities/audit-alert.interface";
import { Expertise } from "@/models/entities/expertise.interface";

@Component
export default class AuditFilters extends Vue {
  @Prop({ required: false, default: () => [], type: Array })
  private expertises: Expertise[];
  @Prop({ required: false, default: () => [], type: Array })
  private alertConditons: AuditAlert[];
  @PropSync("filters", {
    required: false,
    default: () => {
      return {};
    },
    type: Object,
  })
  private _filters: Omit<AuditRequest, "rows" | "page" | "search">;

  private excelBtn: Omit<CBtn, "type"> = {
    btnClass: "c-btn-outlined",
    label: "Scarica Excel",
    labelClass: "c-label-dark",
    color: "var(--v-light-base)",
    icon: {
      name: "excel",
      fill: "var(--v-primary-lighten2)",
      height: 24,
      width: 24,
      iconStyle: "",
    },
  };

  private dates = [];

  private expertiseId = null;

  private created(): void {
    this.dates = [this._filters.from, this._filters.to];
  }

  private onDatesChanged(): void {
    this._filters.from = this.dates[0] || null;
    this._filters.to = this.dates[1] || null;
    this.$emit("on-change");
  }
}
